import localesEn from "./en/common.json";
import localesEs from "./es/common.json";
import localesFr from "./fr/common.json";
import localesKo from "./ko/common.json";
import localesRu from "./ru/common.json";
import localesZh from "./zh/common.json";
import localesPt from "./pt/common.json";
import localesDe from "./de/common.json";
import localesJa from "./ja/common.json";

export default function getCommonLocales(locale) {
	switch (locale) {
		case "pt":
			return localesPt;
		case "es":
			return localesEs;
		case "ru":
			return localesRu;
		case "fr":
			return localesFr;
		case "ko":
			return localesKo;
		case "zh":
			return localesZh;
		case "de":
			return localesDe;
		case "ja":
			return localesJa;
		default:
			return localesEn;
	}
}
