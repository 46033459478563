export const FOOTER_LINKS = [
	{
		id: 1,
		lists: [
			{
				category: "tools",
				children: [
					{ name: "background_remover", url: "/remove-video-bg/" },
					{ name: "Turn photo into video", url: "/turn-photo-into-video/" },
					{ name: "image_generator", url: "/text-to-image-generator/" },
					{ name: "photo_into_painting", url: "/photo-into-painting/" },
					{ name: "erase_replace", url: "/erase-and-replace/" },
					{ name: "interior_redesign", url: "/interior-design-planner/" },
					// { name: "expand_Image", url: "/extend-image/" },
					{ name: "page_editor_h1", url: "/editor/" },
				],
			},
		],
	},
	{
		id: 2,
		lists: [
			{
				category: "Resources",
				children: [
					{ name: "FAQ", url: "/faq/", prefetchAtr: true },
					{ name: "Blog", url: "/blog/", prefetchAtr: true },
					{ name: "Pricing", url: "/pricing/", prefetchAtr: true },
					{ name: "Affiliate Program", url: "/affiliates/", prefetchAtr: true },
				],
			},
			// { category: "product", children: [{ name: "Pricing", url: "/pricing/" }] },
		],
	},
	{
		id: 3,
		lists: [
			{
				category: "Company",
				children: [
					{ name: "Contact us", url: "/contact-us/", prefetchAtr: true },
					{ name: "Terms of Use", url: "/terms-of-use/", prefetchAtr: true },
					{ name: "Privacy Policy", url: "/privacy-policy/", prefetchAtr: true },
					{ name: "License agreement", url: "/license-agreement", prefetchAtr: true },
					{ name: "About us", url: "https://deelvin.com/about-deelvin", relAtr: true },
				],
			},
		],
	},
	{
		id: 4,
		lists: [
			{
				category: "use_cases_footer",
				children: [
					{ name: "page_barbie_selfie_header", url: "/barbie-selfie-generator/", prefetchAtr: true },
					{ name: "barbie_interior", url: "/barbie-interior/", prefetchAtr: true },
					{ name: "page_anime_generator_header", url: "/anime-generator/", prefetchAtr: true },
					{ name: "page_sketch_h1", url: "/photo-to-sketch/", prefetchAtr: true },
					{ name: "use_cases_footer", url: "/landings/", prefetchAtr: true },
				],
			},
		],
	},
];

export const SOCIAL_LINKS = [
	{
		link: "https://www.facebook.com/BgRemEditor",
		image: "/footer/socials/facebook_white.svg",
		name: "facebook",
	},
	{
		link: "https://instagram.com/bgrem_ai",
		image: "/footer/socials/instagram_white.svg",
		name: "instagram",
	},
	{
		link: "https://www.youtube.com/@BgRem_ai",
		image: "/footer/socials/youtube_white.svg",
		name: "youtube",
	},
	{
		link: "https://twitter.com/BgRemAI",
		image: "/footer/socials/twitter_white.svg",
		name: "twitter",
	},
	{
		link: "https://www.pinterest.com/bgrem_ai/",
		image: "/footer/socials/pinterest_white.svg",
		name: "pinterest",
	},
];
