import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

function useSmartResize(triggerWidth) {
  const [isShown, setIsShown] = useState(true);
  const [windowWidth, setWindowWidth] = useState(false);

  const observeWindowWidth = () => {
    if (windowWidth !== window.innerWidth) {
      window.innerWidth > triggerWidth ? setIsShown(true) : setIsShown(false);
    }
  };

  const debouncedObserver = useDebouncedCallback(observeWindowWidth, 10);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    observeWindowWidth();
    window.addEventListener("resize", debouncedObserver);
    return () => window.removeEventListener("resize", debouncedObserver);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [isShown, setIsShown];
}

export default useSmartResize;
