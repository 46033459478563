import { useState, useEffect, memo } from 'react';
import { useRouter } from 'next/router';
import getCommonLocales from '../locales/locales_common';

export function Cookies() {
  const router = useRouter();
  const [cookiesOn, setCookiesOn] = useState(false);
  const { locale } = router;
  const dictionary = getCommonLocales(locale)

  const cookiesOff = () => {
    localStorage.setItem('cookiesSession', Date.now());
    setCookiesOn(false)
  }

  useEffect(() => {
    let CookiesSession = localStorage.getItem('cookiesSession');

    const doNeedShowMessage = !CookiesSession

    if (doNeedShowMessage && !router?.query?.app) {
      setCookiesOn(true)
    } else {
      cookiesOff()
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const cookiesText = `${dictionary["This website uses cookies to enhance your user experience."]} ${dictionary["Read our privacy policy and terms of use for more"]}`

  return (
    <>
      {cookiesOn ?
        <div className="cookies_wrapper on" >
          <div className="cookies_text" dangerouslySetInnerHTML={{ __html: cookiesText }} />
          <button className="btn" onClick={cookiesOff}>{dictionary["Ok, thanks"]}</button>
        </div> : ''
      }
    </>
  )
}

export const MemoizedCookies = memo(Cookies);